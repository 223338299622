@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

:root {
  --font-min: 10px;
  --font-smallest: 11px;
  --font-small: 12px;
  --font-regular: 14px;
  --font-medium: 15px;
  --font-default: 16px;
  --font-large: 18px;
  --font-larger: 28px;
  --font-largest: 32px;
  --font-title: 38px;
  --font-max: 40px;
  --font-max2: 48px;

  --default-font-color: #061938;
  --default-dark-gray: #ddd;

  --primary-color: #1d2680;
  --secondary-color: #0d008c;
  --leve-color: #1d2680;
  --font-color: #313456;
  --info-color: #66b647;
  --success-color: #3779c5;
  --danger-color: rgb(245, 73, 21);
  --default-color: #426fa1;

  --dark-gray: #444444;
  --regular-gray: #555555;
  --medium-gray: #7d8073;
  --light-gray: #fdfdfd;

  --primary-color-hover: #b4d783;
  --secondary-color-hover: #2b3391;
}

label.required::after {
  content: " *";
  color: red;
}

#table-td-react-quill {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

#textarea-react-quill {
  width: 100%;
}

#textarea-react-quill .ql-editor {
  min-height: 96px;
  border-top: 1px solid;
  border-color: #ccc;
}

#textarea-react-quill .ql-toolbar.ql-snow {
  display: none !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.spinner.small {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 35px;
  height: 35px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#global-modal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  z-index: 4;

  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker-wrapper {
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
